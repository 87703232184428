import React, { Component } from 'react'
import { connect } from "react-redux"
import { toggle, bio } from "../../modules/actions"

import MarqueeText from 'react-marquee-text-component'

import logo from '../../assets/img/logo.svg'

class Header extends Component {

    width = window.innerWidth;


    onHover = () => {
        // this.props.bio(this.props.episode.bio)
    }

    onLeave = () => {
        // this.props.bio("")
    }

    render() {

        !this.props.list && this.props.bio(this.props.episode.bio)

        if (!this.props.show_about || this.width <= 980 ) {
            return (
                <header
                    onClick={() => {
                        this.props.toggle("list")
                    }}    
                    onMouseOver={this.onHover}
                    onMouseLeave={this.onLeave}
                >
                    <h2>
                        {this.props.episode.cat_no}
                    </h2>
                    
                    <h1 className={
                        this.props.list || this.props.show_about
                            ? "list"
                            : ""
                    }>
                        { this.width > 980
                                ? <MarqueeText text={this.props.episode.artist + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"} repeat={8} />
                                : this.props.episode.artist
                        }
                    </h1>
                    <img src={logo} alt="/100"/>
                </header>
            )
        } else {
            return (
                <header
                    onClick={() => {
                        this.props.toggle("list")
                }} className="about-header">

                    <h2>
                    {
                        "A CERTAIN N° OF THINGS".length > this.width / 55 && this.width > 980
                            ? <MarqueeText text={"A CERTAIN N° OF THINGS\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}/>
                            : "A CERTAIN N° OF THINGS"
                    }
                    </h2>
                    
                    <img src={logo} alt="/100" />
                </header>
            )
        }


    }
}

const mapStateToProps = (state) => {
    return {
      playing: state.playing,
      list: state.list,
      show_about: state.show_about,
      bio: state.bio,
    }
  }
  
  export default connect(
      mapStateToProps,
      { toggle, bio }
      )(Header);