import React, { Component } from "react"
import { connect } from "react-redux"

import { set_duration, update_time, loading } from '../../modules/actions'

import ReactPlayer from 'react-player'

class Player extends Component {

  handle() {
    if (this.player) {
      this.props.playing ? this.player.play() : this.player.pause()
    }
  }

  getTime(time) {
    if (!isNaN(time)) {
      return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
    }
  }

  handleTime(e) {
    this.props.update_time(e.target.currentTime)
    this.props.set_duration(e.target.duration)
    this.timeBar.value = e.target.currentTime
  }

  videoSize() {
    if (window.innerHeight > window.innerWidth) {
      return window.innerWidth
    } else {
      return window.innerHeight - 180
    }
  }

  componentDidUpdate() {
    this.handle()
  }

  componentDidMount() {
    this.handle()
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.removeEventListener("timeupdate", () => { });
    }
  }


  render() {

    if (this.props.initialized) {
      return (
        <div className="player">

          <div className="headings">
            <span className="episode">
              <span>EPISODE</span>
              <span>N°</span>
            </span>
            <span>
              {this.props.episodes[this.props.current].release_date}
            </span>
          </div>

          <span className="currentTime">{this.getTime(this.props.time)}</span>
          <span className="duration">{this.getTime(this.props.duration)}</span>
          <input
            type="range"
            id="seek"
            defaultValue={this.props.time}
            max={this.props.duration}
            ref={ref => this.timeBar = ref}
            onChange={(e) => { this.player.currentTime = e.target.value }}
          />
          <audio
            onTimeUpdate={(e) => this.handleTime(e)}
            ref={ref => this.player = ref}
          >
            <source
              src={"https://admin.ofhundred.com/" + this.props.episodes[this.props.current].file}
              type="audio/mpeg"
            />
          </audio>

          <ReactPlayer
            url={this.props.episodes[this.props.current].vimeo}
            config={{
              vimeo: {
                playerOptions: {
                  playsinline: 1,
                  muted: 1,
                }
              }
            }}
            playing={this.props.playing}
            loop={true}
            width={this.videoSize()}
            height={this.videoSize()}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              top: window.innerHeight > window.innerWidth ? "50%" : "0px",
              transform: window.innerHeight > window.innerWidth ? "translateY(-50%)" : "none",
            }}
            onReady={() => {
              this.props.loading(false)
            }}
          />
        </div>
      )
    } else {
      return (null)
    }

  }
}


const mapStateToProps = (state) => {
  return {
    current: state.current,
    episodes: state.episodes,
    playing: state.playing,
    initialized: state.initialized,
    time: state.time,
    duration: state.duration
  }
}

export default connect(
  mapStateToProps,
  { set_duration, update_time, loading }
)(Player);


