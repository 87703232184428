import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import PlayButton from '../buttons/PlayButton'
import GridButton from '../buttons/GridButton'
import ListButton from '../buttons/ListButton'
import PrevButton from '../buttons/PrevButton'
import NextButton from '../buttons/NextButton'
import AboutButton from '../buttons/AboutButton'

class Footer extends Component {

    render() {

        if (this.props.episode !== undefined) {
            return (
                <footer>
                    <PrevButton />
                    <NextButton />
                    
                    <PlayButton />
                    
                    <div className="bio">
                        {this.props.bio}
                    </div>

                    <GridButton />
                    <ListButton />
                    <AboutButton />
                </footer>
            )
        } else {
            return (
                <footer>
                    <h2>
                        {this.props.featured.cat_no}
                    </h2>
                    <h2>
                        {this.props.featured.artist}
                    </h2>
                    <Link to={this.props.featured.cat_no} className="select">
                        select
                    </Link>
                </footer>
            )
        }
    }

}

export default Footer