import React, { Component } from 'react'
import { connect } from "react-redux"

import { select, toggle, play } from '../../modules/actions'
import ListItem from './ListItem'

class List extends Component {
    
    render() {

        return (
            <div className="layover list">

                {this.props.episodes.slice().reverse().map((single, index) => {
                    return (
                        <ListItem single={single} key={index} />
                    )
                })}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        current: state.current,
        episodes: state.episodes,
    }
}

export default connect(
    mapStateToProps,
    { select, toggle, play }
)(List);


