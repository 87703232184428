import {
  DATA_LOADED,
  ABOUT_LOADED,
  INIT_PLAYER,
  SELECT,
  PLAY,
  PAUSE,
  UPDATE_TIME,
  SET_DURATION,
  TOGGLE,
  LOADING,
  BIO,
} from "./action-types";

const initialState = {
  episodes: [],
  about: {},
  current: undefined,
  playing: false,
  initialized: false,
  time: 0,
  duration: 0,
  ready: false,
  grid: false,
  list: false,
  show_about: false,
  loading: true,
  bio: "",
};


export default (state = initialState, action) => {
  switch (action.type) {

    case DATA_LOADED:
      return Object.assign({}, state, {
        episodes: state.episodes.concat(action.payload),
      })

    case ABOUT_LOADED:
        return Object.assign({}, state, {
          about: action.payload,
        })

    case SELECT:
      if (state.current !== action.payload) {
        return Object.assign({}, state, {
          current: action.payload,
          bio: state.episodes[action.payload].bio
        })
      }

    // eslint-disable-next-line
    case INIT_PLAYER:
      return Object.assign({}, state, {
        initialized: true
      })

    case PLAY:
      return Object.assign({}, state, {
        playing: true
      })

    case PAUSE:
      return Object.assign({}, state, {
        playing: false
      })

    case UPDATE_TIME:
      return Object.assign({}, state, {
        time: action.payload
      })

    case SET_DURATION:
      return Object.assign({}, state, {
        duration: action.payload
      })

    case TOGGLE:
      if (action.payload === "grid") {
        return Object.assign({}, state, {
          list: false,
          show_about: false,
          grid: state.grid ? false : true
        })
      } else if (action.payload === "list") {
        return Object.assign({}, state, {
          grid: false,
          show_about: false,
          list: state.list ? false : true
        })
      } else if (action.payload === "show_about") {
        return Object.assign({}, state, {
          grid: false,
          list: false,
          show_about: state.show_about ? false : true
        })
      }

      // eslint-disable-next-line
    case LOADING:
      return Object.assign({}, state, {
        loading: action.payload
      })

      case BIO:
        return Object.assign({}, state, {
          bio: action.payload
        })

    default:
      return state
  }
};