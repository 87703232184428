// react imports
import React, { Component } from "react";

// redux imports
import { connect } from "react-redux";
import { getEpisodes, getAbout, select } from "./modules/actions";

// router imports
// react-native
//import { NativeRouter } from "react-router-native";
// react-dom (what we'll use here)
import { BrowserRouter, Route, Switch } from "react-router-dom";


// component imports
import Main from "./components/Main/Main"
import Layovers from "./components/layovers/Layovers"
import Loading from './components/Loading/Loading'

// scsss imports

//animation imports
import { Transition, config } from 'react-spring/renderprops'


export class App extends Component {

  componentDidMount() {

    //fetch all data
    this.props.getEpisodes();
    this.props.getAbout();

  }

  render() {
    return (

      <div className="main">
        {
          this.props.episodes.length
            ? <div>
            
              <BrowserRouter>
                <Layovers />
                <Switch>
                  {
                    this.props.episodes.map((episode, index) => {
                      return (
                        <Route

                          path={"/" + episode.cat_no}
                          render={() => {
                            this.props.select(index)
                            return (<Main />)
                          }
                          }
                          key={episode.cat_no}
                        />
                      )
                    })
                  }
                  <Route path="/" render={() => {
                    this.props.select(this.props.episodes.length - 1)
                    return (<Main />)
                  }
                  }
                  />
                </Switch>
              </BrowserRouter>
            </div>
            : <div></div>
        }

        <Transition
          items={this.props.loading}
          from={{
            opacity: 1,
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 10,
          }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          config={config.slow}
          trail={100}
        >
          {show => show && (props => <div style={props}><Loading /></div>)}
        </Transition>

      </div>
    )


  }

}


const mapStateToProps = (state) => {
  return {
    current: state.current,
    episodes: state.episodes,
    loading: state.loading,
  }
}


export default connect(
  mapStateToProps,
  { getEpisodes, getAbout, select }
)(App);