import { DATA_LOADED, ABOUT_LOADED, SELECT, PLAY, PAUSE, INIT_PLAYER, UPDATE_TIME, SET_DURATION, TOGGLE, LOADING, BIO } from './action-types'
// import Cockpit from "./cockpit"

import CockpitSDK from "cockpit-sdk"
export const Cockpit = new CockpitSDK({
  host: "https://admin.ofhundred.com", // e.g. local docker Cockpit.
  accessToken: "01eed017f82a3a4bb643ceb3447924"
});


export function getEpisodes() {
  return dispatch => {
    Cockpit.collectionGet("episodes", { limit: 100, sort: {cat_no:1} })
      .then(response => {
        dispatch({ type: DATA_LOADED, payload: response.entries });
      });
  };
}

export function getAbout() {
  return dispatch => {
    Cockpit.singletonGet("About")
      .then(response => {
        dispatch({ type: ABOUT_LOADED, payload: response });
      });
  };
}

export const select = (id) => {
  return dispatch => {
    dispatch({type: SELECT, payload: id})
  }
}

export function init() {
  return {type: INIT_PLAYER, payload: null}
}

export function play() {
  return {type: PLAY, payload: null}
}

export function pause() {
  return {type: PAUSE, payload: null}
}

export const update_time = (time) => {
  return {type: UPDATE_TIME, payload: time}
}

export const set_duration = (time) => {
  return {type: SET_DURATION, payload: time}
}

export const toggle = (screen) => {
  return dispatch => {
    dispatch({type: TOGGLE, payload: screen})
  }
}

export const loading = (load) => {
  return {type: LOADING, payload: load}
}

export const bio = (bio) => {
  return {type: BIO, payload: bio}
}