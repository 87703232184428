import React, { Component } from 'react'
import { connect } from "react-redux"

import { select, toggle, play } from '../../modules/actions'
import GridItem from './GridItem'

class Grid extends Component {
    render() {
        return(
            <div className="layover grid">

                {this.props.episodes.map((single, index) => {
                    return(
                        <GridItem single={single} key={index} />
                    )
                })}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      current: state.current,
      episodes: state.episodes,
    }
  }
  
  export default connect(
    mapStateToProps,
    { select, toggle, play }
    )(Grid);
  
  
  