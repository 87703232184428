import React, { Component } from 'react'
import { connect } from "react-redux"

import { play, pause, init } from "../../modules/actions"

import './buttons.scss'

class PlayButton extends Component {


    render() {

        if (this.props.playing && this.props.deactivate !== true) {

            return (
                <button
                    className="button pause"
                    onClick={() => {
                        this.props.init()
                        this.props.pause()
                    }}>
                    pause
                </button> 
            )
        } else {
            return (
                <button
                    className="button play"
                    onClick={() => {
                        this.props.init()
                        this.props.play()
                    }}>
                    play
                </button>
            )
        }



    }

}


const mapStateToProps = (state) => {
    return {
      playing: state.playing,
    }
  }
  
  export default connect(
      mapStateToProps,
      { play, pause, init }
      )(PlayButton);