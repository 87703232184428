import React, { Component } from 'react'
import { connect } from "react-redux"

import { Link } from "react-router-dom"

import './buttons.scss'

class NextButton extends Component {

    prev = this.props.current >= this.props.episodes.length - 1
        ? this.props.episodes[0].cat_no
        : this.props.episodes[this.props.current + 1].cat_no

    render() {
        return (
            <Link to={"/" + this.prev}>
                <button className={"button prev-button prev"} >
                    Next
                </button>
            </Link>
        )

    }

}

const mapStateToProps = (state) => {
    return {
        current: state.current,
        episodes: state.episodes,
    }
}

export default connect(
    mapStateToProps,
    { }
)(NextButton);