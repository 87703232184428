import React, { Component } from 'react'
import { connect } from "react-redux"

import { toggle } from "../../modules/actions"

import './buttons.scss'

class AboutButton extends Component {

    render() {
            return (
                <button
                    className={
                        this.props.show_about ? "button active" : "button"
                        + " about-button"
                    }
                    onClick={() => {
                        this.props.toggle("show_about")
                    }}>
                    About
                </button> 
            )

    }

}


const mapStateToProps = (state) => {
    return {
      show_about: state.show_about,
    }
  }
  
  export default connect(
      mapStateToProps,
      { toggle }
      )(AboutButton);