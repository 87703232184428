import React, { Component } from "react";
import { connect } from "react-redux";

import Header from "./Header"
import Player from "./Player"
import Footer from "./Footer"

import "./main.scss"


class Main extends Component {

  render() {

    return (
      <main>

        <Header
          episode={this.props.episodes[this.props.current]}
        />

        <Player />

        <Footer
          episode={this.props.episodes[this.props.current]}
          featured={this.props.episodes[this.props.episodes.length - 1]}
          bio = {this.props.bio}
        />


      </main >
    )

  }

}

const mapStateToProps = (state) => {
  return {
    current: state.current,
    episodes: state.episodes,
    playing: state.playing,
    initialized: state.initialized,
    grid: state.grid,
    list: state.list,
    bio: state.bio,
  }
}

export default connect(mapStateToProps)(Main);


