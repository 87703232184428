import React, { Component } from 'react'
import { connect } from "react-redux"

import { select, toggle, play } from '../../modules/actions'

class About extends Component {

    width = window.innerWidth

    render() {
        return (
            <div className="layover about">
                <h2>
                    {this.props.about.text}
                </h2>
                <div className="links">
                    <a href="/" className="col-1">
                        W
                    </a>
                    <a href="/" className="col-2">
                        WWW.
                    </a>
                    <a href="/" className="col-3">
                    </a>
                    <a href="/" className="col-4">
                        OFHUNDRED
                    </a>
                    <a href="/" className="col-5">
                        .COM
                    </a>
                    <a href="mailto:mail@ofhundred.com" target="_blank" className="col-1" rel="noopener noreferrer">
                        M
                        </a>
                    <a href="mailto:mail@ofhundred.com" target="_blank" className="col-2" rel="noopener noreferrer">
                        MAIL
                        </a>
                    <a href="mailto:mail@ofhundred.com" target="_blank" className="col-3" rel="noopener noreferrer">
                        @
                        </a>
                    <a href="mailto:mail@ofhundred.com" target="_blank" className="col-4" rel="noopener noreferrer">
                        OFHUNDRED
                        </a>
                    <a href="mailto:mail@ofhundred.com" target="_blank" className="col-5" rel="noopener noreferrer">
                        .COM
                        </a>
                    <a href="https://instagram.com/ofhundred" target="_blank" className="col-1" rel="noopener noreferrer">
                        IG
                        </a>
                    <a href="https://instagram.com/ofhundred" target="_blank" className="col-2" rel="noopener noreferrer">
                    </a>
                    <a href="https://instagram.com/ofhundred" target="_blank" className="col-3" rel="noopener noreferrer">
                        @
                        </a>
                    <a href="https://instagram.com/ofhundred" target="_blank" className="col-4" rel="noopener noreferrer">
                        OFHUNDRED
                        </a>
                    <a href="https://instagram.com/ofhundred" target="_blank" className="col-5" rel="noopener noreferrer">
                    </a>
                </div>
                <div className="about" dangerouslySetInnerHTML={{__html: this.props.about.small_text}} >
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        current: state.current,
        about: state.about,
    }
}

export default connect(
    mapStateToProps,
    { select, toggle, play }
)(About);


