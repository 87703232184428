import React, { Component } from "react";
import { connect } from "react-redux";

// import { useTransition, animated } from 'react-spring'
import { Transition, animated } from 'react-spring/renderprops'

import Grid from "./Grid"
import List from "./List"
import About from "./About"

import "../Main/main.scss"


import "./layovers.scss"

class Layovers extends Component {
  render() {
    return (
      <div className="layovers">
        <Transition
          native
          items={this.props.grid}
          from={{
            position: "absolute",
            bottom: '-80px',
            height: 'calc(100% - 180px)',
            width: '100%',
            zIndex: 3,
            opacity: 0,
          }}
          enter={{
            opacity: 1,
            bottom: '80px',
          }}
          leave={{
            opacity: 0,
            bottom: '-80px',
          }}>
          {show => show && (props => <animated.div style={props}><Grid /></animated.div>)}
        </Transition>

        <Transition
          native
          items={this.props.list}
          from={{
            position: "absolute",
            top: '-80px',
            height: 'calc(100% - 180px)',
            width: '100%',
            zIndex: 3,
            opacity: 0,
          }}
          enter={{
            opacity: 1,
            top: '100px',
          }}
          leave={{
            opacity: 0,
            top: '-80px',
          }}>
          {show => show && (props => <animated.div style={props}><List /></animated.div>)}
        </Transition>

        <Transition
          native
          items={this.props.show_about}
          from={{
            position: "absolute",
            bottom: '-80px',
            height: 'calc(100% - 180px)',
            width: '100%',
            zIndex: 3,
            opacity: 0,
          }}
          enter={{
            opacity: 1,
            bottom: '80px',
          }}
          leave={{
            opacity: 0,
            bottom: '-80px',
          }}>
          {show => show && (props => <animated.div style={props}><About /></animated.div>)}
        </Transition>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    grid: state.grid,
    list: state.list,
    show_about: state.show_about,
  }
}

export default connect(mapStateToProps)(Layovers);

