import React, { Component } from "react"

import logo from '../../assets/img/logo.svg'
import "./loading.scss"


class Loading extends Component {

  
  render() {
    return(
        <div id="loading">
            <h1>
                A Certain N° of Things 
            </h1>
            <img src={logo} alt="logo" />
        </div>
    )

  }
}


export default Loading;