import React, { Component } from 'react'
import { connect } from "react-redux"

import { Link } from "react-router-dom"

import MarqueeText from 'react-marquee-text-component'

import { Cockpit, select, toggle, play, bio } from '../../modules/actions'

class GridItem extends Component {

    width = window.innerWidth
    marquee = false
    currentBioText = ""

    img = () => {
        return Cockpit.image(this.props.single.thumbnail.path, [
            240,
            480
          ]);
    }

    componentDidMount = () => {
        this.currentBioText = this.props.bio_text
    }

    onHover = () => {
        this.props.bio(this.props.single.bio)
    }

    onLeave = () => {
        this.props.bio(this.currentBioText)
    }

    render() {
        return (
            <Link
                to={this.props.single.cat_no}
                className="tile"
                id={this.props.single.cat_no}
                key={this.props.single.cat_no}
                onClick={() => {
                    this.props.toggle("grid")
                    this.props.play()
                }}
                onMouseOver={this.onHover}
                onMouseLeave={this.onLeave}
            >
                <img srcSet={this.img()} alt={this.props.single.artist} />
                <div className="label">
                    <p>
                        {this.props.single.cat_no}
                    </p>
                    <h1>
                        {
                            this.props.single.artist.length > 18
                                ? <MarqueeText text={this.props.single.artist + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"} />
                                : this.props.single.artist
                        }
                    </h1>
                </div>
            </Link>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        current: state.current,
        episodes: state.episodes,
        bio_text: state.bio,
    }
}

export default connect(
    mapStateToProps,
    { select, toggle, play, bio }
)(GridItem);


