import React, { Component } from 'react'
import { connect } from "react-redux"

import { Link } from "react-router-dom"

import MarqueeText from 'react-marquee-text-component'

import { select, toggle, play, bio } from '../../modules/actions'
import PlayButton from '../buttons/PlayButton'


import logo from '../../assets/img/logo.svg'

class ListItem extends Component {

    width = window.innerWidth
    marquee = false
    currentBioText = ""

    componentDidMount = () => {
        this.currentBioText = this.props.bio_text
    }

    onHover = () => {
        this.props.bio(this.props.single.bio)
        this.marquee = true
    }

    onLeave = () => {
        this.props.bio(this.currentBioText)
        this.marquee = false
    }

    render() {
        return (
            <Link
                to={this.props.single.cat_no}
                className="list-item"
                id={this.props.single.cat_no}
                key={this.props.single.cat_no}
                onClick={() => {
                    this.props.toggle("list")
                    this.props.play()
                }}
                onMouseOver={this.onHover}
                onMouseLeave={this.onLeave}
            >
                <h2>
                    {this.props.single.cat_no}
                </h2>
                {
                    this.width > 980
                        ? <PlayButton deactivate={true} />
                        : ""
                }
                <h1 className="list">
                    {
                        this.width > 980
                            ? <MarqueeText text={this.props.single.artist + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"} repeat={8} paused={!this.marquee} />
                            : this.props.single.artist
                    }
                </h1>
                <img src={logo} alt="/100"/>
            </Link>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        current: state.current,
        episodes: state.episodes,
        bio_text: state.bio,
    }
}

export default connect(
    mapStateToProps,
    { select, toggle, play, bio }
)(ListItem);


