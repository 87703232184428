export const DATA_LOADED = "DATA_LOADED";

export const ABOUT_LOADED = "ABOUT_LOADED";

export const INIT_PLAYER = "INIT_PLAYER";

export const SELECT = "SELECT";

export const PLAY = "PLAY";

export const PAUSE = "PAUSE";

export const UPDATE_TIME = "UPDATE_TIME";

export const SET_DURATION = "SET_DURATION";

export const NEXT = "NEXT";

export const PREV = "PREV";

export const TOGGLE = "TOGGLE";

export const LOADING = "LOADING";

export const BIO = "BIO";