import React, { Component } from 'react'
import { connect } from "react-redux"

import { toggle } from "../../modules/actions"

import './buttons.scss'

class ListButton extends Component {

    render() {
            return (
                <button
                    className={
                        this.props.list ? "button active" : "button"
                        + " list-button"
                    }
                    onClick={() => {
                        this.props.toggle("list")
                    }}>
                    List
                </button> 
            )

    }

}


const mapStateToProps = (state) => {
    return {
      playing: state.playing,
      list: state.list,
    }
  }
  
  export default connect(
      mapStateToProps,
      { toggle }
      )(ListButton);